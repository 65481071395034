footer {
	background: #110e09;
	padding: 15px 0;
	text-align: center;
    clear: both;
}
.copyright {
	color: #ccac57;
	font-size: 12px;
}
.Cooperation i {
    display: inline-block;
    height: 36px;
    background: url(../../image/partner-bdo.png) no-repeat;
}
.Cooperation i.bb {
    width: 86px;
    background-position: -240px -2px;
}
.Cooperation i.mg {
    width: 120px;
    background-position: -360px 0;
}
.Cooperation i.pt {
    width: 120px;
    background-position: -480px -2px;
}
.Cooperation i.ag {
    width: 100px;
    background-position: -600px -2px;
}
.Cooperation i.og {
    width: 88px;
    background-position: -720px 0;
}
.Cooperation i.gd {
    width: 80px;
    background-position: -1200px 0;
}
.Cooperation i.sb {
    width: 90px;
    background-position: -960px 0;
}
footer li {
    display: inline-block;
}
footer li a {
    color: #fae9be;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 30px;
}
footer li:not(:last-child):after {
    content: "/";
    display: inline-block;
    font-size: 12px;
    margin: 0 10px;
    color: #f2f2f2;
}
.br_right_bar .br_bar_mnav {
    position: fixed;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
    z-index: 1111;
    padding: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background: #241d17;
    border: 2px solid #fae9be;
}
.br_bar_mnav .br_sbgimg {
    text-align: center;
    cursor: pointer;
    position: relative;
    display: block;
}
.br_bar_mnav .br_sbgimg .br_rightbar_icon {
    background: #241d17;
    color: #ccac57;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
}
.br_bar_mnav .br_sbgimg .br_rightbar_icon svg {
    fill: #fae9be;
    width: 24px;
    height: 24px;
    margin-top: 13px;
}
.br_bar_mnav .br_sbgimg p {
    display: none;
    font-size: 12px;
    text-align: center;
    padding: 0 10px;
    position: absolute;
    white-space: nowrap;
    top: 10px;
    right: 150%;
    border-radius: 3px;
    line-height: 30px;
    color: #241d17;
    background-color: #fae9be;
}
.br_bar_mnav .br_sbgimg:hover .br_rightbar_icon {
    border: 2px solid #fae9be;
    background: #fae9be;
}
.br_bar_mnav .br_sbgimg:hover svg {
    fill: #241d17;
}
.br_bar_mnav .br_sbgimg:hover p {
    display: block;
}
.btn-info-mini {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    cursor: pointer;
}
.popup-backdrop {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100vh;
    padding: 20px;
    background: #000000c2;
    z-index: 9999;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.popup-main {
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    width: 100%;
    max-width: 600px;
    margin: auto;
}
.popup-header {
    background: #ccac57;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    padding: 15px;
}
.popup-content {
    padding: 15px;
    font-size: 20px;
    text-align: left;
    line-height: 1.3;
    max-height: 70vh;
    overflow: auto;
    color: #333;
}
.popup-close {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    background: linear-gradient(to right,#ccac57,#ccac57);
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
}