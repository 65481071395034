.banner-game {
    width: 100%;
    position: relative;
}
.banner-game img {
    width: 100%;
}
.menu-box-game {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.menu-box-game-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}
.box-game {
    margin: 15px;
    position: relative;
    overflow: hidden;
}
.box-game:after {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,.8);
    z-index: 9;
    transition: all .5s;
}
.box-game:hover:after {
    display: block;
}
.box-game-image img {
    vertical-align: bottom;
    width: 100%;
}
.box-game-play {
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all .3s;
    z-index: 10;
}
.box-game-play span {
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 0 solid #000;
    border-radius: 5px;
    color: #fff;
    background: linear-gradient(90deg,#ffd633,#a08722);
    cursor: pointer;
}
.box-game:hover .box-game-play {
    opacity: 1;
}
.sub_logo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
}